"use client"

import { getYear } from "date-fns"

export const Copyright = () => {
  return (
    <div className={"text-[15px] font-normal text-black/[.35]"}>
      © Newie {getYear(new Date())}
    </div>
  )
}
